<template>
    <div>
    <Header class="header-class" v-show="!$vuetify.breakpoint.xs"/>
    <MobileHeaderMenu v-show="$vuetify.breakpoint.xs"/>
        <div>
            <CareerBody/>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import MobileHeaderMenu from "../components/MobileHeaderMenu.vue"
import Footer from "../components/Footer.vue"
import CareerBody from "../components/CareerBody.vue"

export default {
    name: "CareersView",
    components: {
        Header,
        MobileHeaderMenu,
        Footer,
        CareerBody
    }
}
</script>

<style scoped>
.body-container{
  padding-left:12%;
  padding-right:12%;
}
</style>