<template>
<div class="content-container">
        <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <v-row align="center">
                    <v-col lg="2" cols="12">
                        <v-btn elevation="0" class="image-logo" fab dark large color="blue">
                        <v-icon dark>
                            {{Icon}}
                        </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col lg="6" cols="12">
                        <h3 class="service-title">{{Title}}</h3>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="expansion-panel">
                <v-row>
                    <v-col>
                        <p class="paragraph-text">
                            {{Paragraph}} 
                        </p>
                    </v-col>
                </v-row>
                <v-row class="mb-3 mt-0 pdf" v-if="PDF !== undefined">
                    <v-col>
                        <a href="#" @click="toPDF"> Read More </a>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
</div>
</template>

<script>
export default {
    name: "LTLShipping",
    data(){
        return{

        }
    },
    props: {
        Icon: String,
        Paragraph: String,
        Title: String,
        PDF: String,
    },
    methods: {
        toPDF(){
            window.open(`/files/${this.PDF}`);
        }
    }
}
</script>

<style scoped>
.service-title{
    margin-left:14px;
}
.paragraph-text{
    letter-spacing: .1ch;
    line-height: 30px;
    margin-right:5%;
}
.card-class{
    padding:5%;
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 414px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .text-title{
        text-align: center;
    }
    .paragraph-text{
        text-align: center;
        margin-right:0%;
    }
    .service-title{
        text-align: center;
        margin-left:0px;
    }
    .image-logo{
        margin-left:40%;
    }
    .pdf{
        text-align:center;
    }
}
</style>