<template>
<v-container class="header">
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
            <v-sheet
            color="#144058">
            <v-row
            >
                <v-btn
                class="button"
                color="white"
                x-large
                icon
                @click.stop="drawer = !drawer"
                >
                    <svg-icon type="mdi" :path="path"></svg-icon>
                </v-btn>
            </v-row>

            <v-navigation-drawer
            :disable-route-watcher="true"
            v-model="drawer"
            absolute
            temporary
            >

            <v-list dense>
                <v-list-item
                v-for="item in items"
                :key="item.title"
                @click.stop="drawer = !drawer"
                @click="$router.push({ path: item.route }).catch(err => {})"
                link
                >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>

                <v-list-item @click.stop="drawer = !drawer" @click="login">
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title> Login </v-list-item-title>
                </v-list-item-content>

                </v-list-item>
            </v-list>
            </v-navigation-drawer>
        </v-sheet>
        </v-col>
    </v-row>
<v-row>
    <v-col>
        <v-img
        @click="toHome"
            :src="require('../assets/Clarity Freight.png')"
            class="logo"
            contain
            max-height="180"
        />
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMenu } from '@mdi/js';

export default {
    name: "MobileHeaderMenu",
    data() {
        return{
        drawer: null,
        path: mdiMenu,
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard', route:'/' },
          { title: 'About', icon: 'mdi-forum', route: '/' },
          { title: 'Careers', icon: 'mdi-account-hard-hat-outline', route: '/Careers' },
          { title: 'Get A Quote', icon: 'mdi-currency-usd', route: '/GetAQuote' },
        ],
        }
    },
    components: {
        SvgIcon,
    },
    methods: {
        login() {
            window.open('https://onlinefreight.com/customerPortal');
        },
        toHome() {
            if (this.$route.path != '/'){
                this.$router.push('/');
            }
        }
    }
}
</script>

<style scoped>
.logo{
    position:relative;
    bottom:40px;
}
.header{
  background-color:#144058;
  box-shadow:0 2px 15px;
  height:210px;
}
.button{
    position:relative;
    left:360px;
}
</style>