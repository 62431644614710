<template>
<v-container class="career-container" fill-height>
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
            <h1 class="message"> Please send resumes to 
            <a href="mailto:info@clarityfreight.com?subject=Job%20Inquiry%20">
                info@clarityfreight.com 
            </a>
            </h1>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "CareerBody",
}
</script>

<style scoped>
.career-container{
    height:280px;
}
.message{
    text-align: center;
}
</style>