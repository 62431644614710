import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import GetAQuoteView from "../views/GetAQuoteView"
import CareersView from "../views/CareersView"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/GetAQuote",
    name: "GetAQuote",
    component: GetAQuoteView,
  },
  {
    path: "/Careers",
    name: "Careers",
    component: CareersView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
}
});

export default router;
