var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"text-title"},[_vm._v(" Full Service logistics Solutions ---- Like it should be. ")])])],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('Service',{attrs:{"Icon":'mdi-truck',"Title":'LTL Shipping',"Paragraph":`Less than Truckload (LTL) Shipping provides flexible resources and increased efficiency 
                to businesses that need to ship smaller loads. LTL Shipping uses a “hub and spoke” system 
                consisting of smaller local terminals and larger centralized ones to meet shipping needs.`,"PDF":'LTL.pdf'}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('Service',{attrs:{"Icon":'mdi-truck-cargo-container',"Title":'Truckload Shipping',"Paragraph":`Truckload Shipping is the top freight shipping solution for businesses across the United States, representing more than half of domestic shipping. 
            At Clarity Freight, we leverage existing relationships with leading carriers so your freight gets where it needs to be, 
            when it needs to be there at the best possible price.`,"PDF":'Truckload.pdf'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('Service',{attrs:{"Icon":'mdi-train',"Title":'Rail Shipping',"Paragraph":`Intermodal or Rail Shipping services use different modes of transportation to move freight in the most efficient way,
                whether that means rail shipping, over-the-road, 
                or even boat or plane. Intermodal containers allow shipments to be transferred without being unpacked.`}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('Service',{attrs:{"Icon":'mdi-truck-fast-outline',"Title":'Expedited Shipping',"Paragraph":`Same day, next day and second day shipping options are available when you must have your freight delivered in a hurry. 
                When your freight absolutely must arrive as quickly as possible,
                Clarity Freight offers Expedited Shipping. Working with our large network of freight forwarders, carriers and delivery agents, we have the solution for your expedited needs.`}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('Service',{attrs:{"Icon":'mdi-truck-snowflake',"Title":'Refrigerated Shipping',"Paragraph":`Reefers distribute chilled air from the floor via T-shaped decking. 
                As such, they are also called “bottom air delivery units”. Reefers are the only effective way to transport products requiring a temperature controlled environment. 
                The biggest difference between reefers and conventional containers is the temperature control function.`}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('Service',{attrs:{"Icon":'mdi-truck-flatbed',"Title":'Partial Truckload',"Paragraph":`Partial Truckload Shipping when you have too much for LTL (Less than Truckload) but not enough for a dedicated truckload. 
                Shipping 7-16 pallets, let Clarity Freight utilize our carrier network to source a partial truckload carrier to help get the best price possible.`,"PDF":'Partial Truckload.pdf'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }