<template>
<div>
    <Header v-show="!$vuetify.breakpoint.xs"/>
    <MobileHeaderMenu v-show="$vuetify.breakpoint.xs"/>
    <div class="body-container">
        <GetAQuoteForm/>
    </div>
    <Footer/>
</div>
</template>

<script>
import Header from "../components/Header.vue"
import MobileHeaderMenu from "../components/MobileHeaderMenu.vue"
import GetAQuoteForm from "../components/GetAQuoteForm.vue"
import Footer from "../components/Footer.vue"

export default {
    name: "GetAQuoteView",
    components: {
        Header,
        MobileHeaderMenu,
        GetAQuoteForm,
        Footer
    }
}
</script>

<style scoped>
.body-container{
  padding-left:12%;
  padding-right:12%;
}
</style>