<template>
    <v-container class="outer">
        <div id="services-navigate"/>
        <v-row class="row-container">
            <v-col lg="4" cols="6" class="mt-4">
                <h3 class="footer-text">
                    Services
                </h3>
                <div class="sub-bar"/>
                <div class="button-container">
                    <button @click="toService" class="footer-body-text">
                        LTL Shipping
                    </button>
                </div>
                <div class="button-container">
                    <button @click="toService" class="footer-body-text">
                        Truckload Shipping
                    </button>
                </div>
                <div class="button-container">
                    <button @click="toService" class="footer-body-text">
                        Rail Shipping
                    </button>
                </div>
                <div class="button-container">
                    <button @click="toService" class="footer-body-text">
                        Expedited Shipping
                    </button>
                </div>
                <div class="button-container">
                    <button @click="toService" class="footer-body-text">
                        Refrigerated Shipping
                    </button>
                </div>
            </v-col>
            <v-col lg="4" cols="6" class="mt-4">
                <h3 class="footer-text">
                    Contact Us
                </h3>
                <div class="sub-bar"/>
                <p class="contact-info">
                    Clarity Freight
                </p>
                <p class="contact-info">
                    Olathe, KS 66061
                </p>
                <p class="contact-info">
                    (913) 568 1846
                </p>
                <p class="contact-info">
                    <a class="email" href="mailto:info@clarityfreight.com?subject=Inquiry">
                    info@clarityfreight.com
                    </a>
                </p>
            </v-col>
            <v-col cols="4" class="mt-4" v-show="!$vuetify.breakpoint.xs">
                <v-img
                    :src="require('../assets/Logo No Text.png')"
                    class="logo"
                    max-height="150"
                    max-width="150"
                    contain
                />
            <v-row>
                <v-col class="copyright-text">
                    © 2023 Clarity Freight
                </v-col>
            </v-row>
        <v-row>
            <v-col cols="5" class="socials ma-0 pa-0" align="center"> 
                    <svg @click="toLinkedIn" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        class="social-icon linkedin"
                        width="30" height="30"
                        viewBox="0 0 30 30"
                        >
                        <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z">
                        </path>
                    </svg>
    <svg @click="toFacebook" class="facebook social-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
      </v-col>
        </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Footer",
    methods: {
        toLinkedIn() {
            window.open('https://www.linkedin.com/company/clarityfreight/');
        },
        toFacebook(){
            window.open('https://www.facebook.com/clarityfreight');
        },
        toService() {
            if (this.$route.path !== '/') {
                this.$router.push('/');
            }
            else {
                document.getElementById('services-navigate').scrollIntoView({
                    behavior: "smooth"
                })
            }
        }
    }
}
</script>

<style scoped>
#services-navigate{
    width:20px;
    height:20px;
    position:absolute;
    bottom:60%;
}
.email{
    color:white;
}
.row-container{
    margin-left:10%;
}
.copyright-text{
    color:white;
    margin-top:5%;
}
.push-left{
    margin-left:20px;
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 414px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .row-container{
        margin-left: 0px;
    }
  }
.social-icon{
    cursor: pointer;
    margin-right:5%;
}
.facebook{
    position:relative;
    border-radius:7px;
    bottom:3px;
    left:5px;
}
.socials{
    fill:white !important;
}
.facebook:hover{
    fill: var(--clarity-green) !important;
}
.linkedin:hover{
    fill: var(--clarity-green) !important;
}

.outer{
  max-width:100%;
  background-color:#144058;
  box-shadow:0 2px 15px;
}
.footer-text{
    color:white;
    text-transform: uppercase;
    letter-spacing: .1ch;
}
.sub-bar{
    background-color:#3a6575;
    width:100px;
    margin-top:25px;
    margin-bottom:25px;
    height:2px;
}
.logo{
    position:relative;
    left:10px;
}
.button-container{
    margin-bottom:10px;
}
.footer-body-text{
  color:white;
  letter-spacing: .1ch;
  background:none;
  outline:none;
  border:none;
}
.footer-body-text::after{
  content:'';
  height:2px;
  width:0%;
  background-color:#1AAAAA;
  display:block;
  transition: .2s ease-in-out;
}
.footer-body-text:hover::after{
  content:'';
  height:2px;
  width:100%;
  background-color: #1AAAAA;
  display:block;
}
.footer-body-text:hover{
  color:#9DDDDD;
}
.contact-info{
    color:white;
    margin-bottom:10px;
}
</style>