<template>
<div>
  <v-container class="outer" v-show="!$vuetify.breakpoint.xs">
    <v-row class="nav-color">
      <v-col cols="4" align="center" class="pl-16">
          <v-img
          @click="toHome"
            :src="require('../assets/Logo Photoshopped.png')"
            class="logo"
          />
      </v-col>
      <v-col lg="7" class="align-self-center ml-16" align="end">
        <v-btn @click="toHome" x-large color="white" text>Home</v-btn>
        <v-btn @click="toAbout" x-large color="white" text>About</v-btn>
        <v-btn @click="toCareers" x-large color="white" text>Careers</v-btn>
        <v-btn @click="toGetAQuote" x-large color="white" text>Get A Quote</v-btn>
        <v-btn @click="login" x-large text color="white">
        <svg-icon type="mdi" :path="path"></svg-icon>
          Login
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <div class="mobile-menu">
    <MobileHeaderMenu v-show="$vuetify.breakpoint.xs"/>
  </div>
  <div id="about"/>
</div>
</template>

<script>
import MobileHeaderMenu from "../components/MobileHeaderMenu.vue"
import { mdiAccount } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';

export default {
  name: "TopHeader",
  data(){
    return{
      path: mdiAccount,
    }
  },
  components: {
    SvgIcon,
    MobileHeaderMenu
  },
  methods: {
    toGetAQuote() {
      if (this.$route.path != '/GetAQuote') {
        this.$router.push('/GetAQuote');
      }
    },
    toHome() {
      if (this.$route.path != '/'){
        this.$router.push('/');
      }
    },
    toAbout() {
      if (this.$route.path == '/') {
          document.getElementById("about").scrollIntoView({
            behavior: "smooth"
        });
      }
      else {
        this.$router.push('/');
      }
    },
    toCareers(){
      if (this.$route.path != '/Careers') {
        this.$router.push('/Careers');
      }
    },
    login() {
      window.open('https://onlinefreight.com/customerPortal');
    }
  }
};
</script>
<style scoped>
#about{
  width:20px;
  height:20px;
  position:absolute;
  top:20%;
  left:15%;
}
.outer{
  max-width:100%;
}
.logo-text{
  position:relative;
  top:30px;
  right:60px;
}
.nav-color{
    background-color:var(--clarity-blue);
}
.logo{
  cursor:pointer;
}
.logo-row{
  /* background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgb(23, 68, 91) 0%, rgba(255,255,255,1) 90%); */
  margin-right:11%;
  margin-left:11%;
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 414px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .outer{
      display:none;
    }

}
</style>