<template>
    <v-container class="ma-0 pa-0">
        <v-row>
            <v-col align="center">
                <h1 class="review-title"> What our customers are saying </h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                <v-icon color="green" v-for="i in 5" :key="i"> mdi-star </v-icon>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-carousel cycle hide-delimiters height="auto">
                    <v-carousel-item v-for="(item, i) in reviews" :key="i">
                        <v-sheet color="#144058" min-height="400">
                            <v-container>
                                <v-row>
                                    <v-col align="center">
                                        <!-- <div class="review-title">
                                            {{ item.review }}
                                        </div> -->
                                        <v-btn x-large elevation="0" class="image-logo" fab dark large color="#339753">
                                            <v-icon color="white" x-large dark>
                                                mdi-account
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col align="center">
                                        <p class="review-text">
                                            "{{ item.review }}"
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col align="center">
                                        <p class="review-author">
                                        - {{ item.author }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Review",
    data() {
        return{
            reviews: [
                { author: 'Alan Musser', 
                review: `I use clarity freight because they have competitive pricing and great service. 
                Clarity Freight allows us to choose the carrier that handles our products,
                 which gives us comfort while still getting the best rates, and being able to choose how fast we need it shipped.` },
            ]
        }
    }
}
</script>

<style scoped>
.review-title{
    font-size:32px;
    font-weight: bold;
    letter-spacing: .1ch;
    text-transform: uppercase;
    color: var(--clarity-green);
}
.review-text{
    color:white;
    font-size:22px;
    padding-right:20%;
    padding-left:20%;
    font-weight:bold;
    letter-spacing: .05ch;
}
.review-author{
    font-size:16px;
    color:white;
    font-weight:bold;
    letter-spacing: .05ch;
    font-style: italic;
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 414px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .review-text{
        font-size:20px;
    }
}
</style>