<template>
<div>
  <Header class="header-class"/>
  <div class="body-container">
    <GetAQuote :Text="`Need an easy freight shipping quote? We are here to help.`" class="get-a-quote"/>
    <AboutSection class="about"/>
    <TypesOfService/>
    <Review class="review"/>
    <GetAQuoteSecond :Text="`Ready for Clarity Freight to exceed your expectations? Contact us now!`" class="get-a-quote-second" />
  </div>
    <Footer/>
</div>
</template>

<script>
import Header from "../components/Header";
import GetAQuote from "../components/GetAQuote"
import AboutSection from "../components/AboutSection.vue"
import TypesOfService from "../components/TypesOfService.vue"
import Review from "../components/Review.vue"
import GetAQuoteSecond from "../components/GetAQuoteSecond.vue"
import Footer from "../components/Footer.vue"

export default {
  name: "HomeView",

  components: {
    Header,
    GetAQuote,
    AboutSection,
    TypesOfService,
    Review,
    GetAQuoteSecond,
    Footer
  },
};
</script>

<style scoped>
.header-class{
  margin-bottom:12%;
}
.body-container{
  padding-left:12%;
  padding-right:12%;
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 414px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .get-a-quote{
      margin-top: 20% !important;
    }
  }
.get-a-quote{
  margin-bottom:10% !important;
}
.get-a-quote-second{
  margin-top:10% !important;
  margin-bottom:10% !important;
}
.about{
  margin-bottom:10% !important;
}
.review{
  margin-top:10% !important;
}
</style>