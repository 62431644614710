<template>
<v-container class="ma-0 pa-0">
    <v-row>
        <v-col>
            <h1 class="text-title"> WHO WE ARE </h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" lg="6">
            <v-card min-height="400" class="content-card">
                <p class="paragraph-text">
                    At Clarity Freight, our agents are empowered to thrive and are equipped for limitless success. 
                    Powered by our best-in-class back office support, our agents focus on what they love most about being a freight broker. 
                    <br/>
                    <br/>
                    Want to be part of a team of people who are just as driven and ambitious as you are? Cultivate your future and grow along with us.
                </p>
                <v-btn @click="toCareers" class="contact-btn mt-4">
                    CAREERS
                </v-btn>
            </v-card>
        </v-col>
        <v-col cols="12" lg="6">
            <v-img
            min-height="400"
            :src="require('../assets/workers.jpg')">
            </v-img>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "AboutSection",
    methods: {
        toCareers(){
            this.$router.push('/Careers');
        }
    }
}
</script>

<style scoped>
.paragraph-text{
    letter-spacing: .1ch;
    line-height: 30px;
}
.content-card{
    padding:30px;
}
.text-subtitle{
    text-align: center;
    color: var(--clarity-green);
    margin-bottom:5%;
}
.text-title{
    color: var(--clarity-green);
    text-align: center;
    margin-bottom:5%;
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 414px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .paragraph-text{
        text-align: center;
    }
    .contact-btn{
        margin-left:30%;
        margin-bottom:5%;
    }
  }
</style>