<template>
<v-container class="outer-container ma-0 pa-0">
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
            <v-img
            class="freight-image"
            :src="require('../assets/Freight Truck 2.jpg')">
            <div class="text-container">
                <v-row>
                    <v-col class="freight-text">
                        <h1> {{Text}} </h1>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn @click="toGetAQuote" class="get-a-quote-button" large>
                            GET A QUOTE
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            </v-img>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "GetAQuoteSecond",
    props: {
        Text:String,
    },
    methods: {
        toGetAQuote() {
            this.$router.push('/GetAQuote');
        }
    }
}
</script>

<style scoped>
.freight-text{
    text-transform: uppercase;
}
.text-container{
    color:white;
    margin:10%;
    text-align: center;
}
</style>