<template>
<v-container class="outer-container ma-0 pa-0">
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" align="center">
            <v-img
            max-height="300"
            class="quote-image"
            :src="require('../assets/quote.jpg')">
            <div class="text-container">
                <v-row>
                    <v-col class="quote-text">
                        <h1> Get a quote </h1>
                    </v-col>
                </v-row>
            </div>
            </v-img>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h1 class="quote-header"> We can deliver. Let's chat. </h1>
        </v-col>
    </v-row>
    <v-card class="form-card">
        <v-row>
            <v-col lg="6" cols="12">
                <v-text-field v-model="companyName" maxlength="70" label="Company Name:">
                </v-text-field>
            </v-col>
            <v-col lg="6" cols="12">
                <v-text-field maxlength="70" v-model="contactName" label="Contact Name:">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col lg="6" cols="12">
                <v-text-field v-model="phone" maxlength="20" label="Phone:">
                </v-text-field>
            </v-col>
            <v-col lg="6" cols="12">
                <v-text-field v-model="email" maxlength="70" label="Email:">
                </v-text-field>
                    <v-row class="mt-0" v-if="!$v.email.required">
                        <v-col>
                            <v-icon small color="red"> mdi-alert </v-icon> 
                            <span class="error-message"> Email is required. </span>
                        </v-col>
                    </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col lg="6" cols="12">
                <v-text-field v-model="origin" maxlength="70" label="Origin Zip/City">
                </v-text-field>
            </v-col>
            <v-col lg="6" cols="12">
                <v-text-field v-model="destination" maxlength="70" label="Destination Zip/City:">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col lg="6" cols="12">
                <v-text-field v-model="numPallets" maxlength="70" label="Number of Pallets/Pieces:">
                </v-text-field>
            </v-col>
            <v-col lg="6" cols="12">
                <v-text-field v-model="dimensions" maxlength="70" label="Dimensions:">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col lg="6" cols="12">
                <v-text-field v-model="totalWeight" maxlength="70" label="Total Weight">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-textarea v-model="commodityDescription" maxlength="1000" filled label="Description of Commodity:">
                </v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-textarea v-model="specialInstructions" maxlength="1000" filled label="Any special instructions (lift-gate, delivery appointment, etc.)">
                </v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn @click="getQuoteClick" :disabled="!$v.email.required">
                    Get a quote
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!$v.email.required">
            <v-col>
                <v-icon small color="red"> mdi-alert </v-icon> 
                <span class="error-message"> One or more fields are missing. </span>
            </v-col>
        </v-row>
    </v-card>
</v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    name: "GetAQuoteForm",
    data() {
        return {
            companyName: '',
            contactName: '',
            phone: '',
            email: '',
            origin: '',
            destination: '',
            numPallets: '',
            dimensions: '',
            totalWeight: '',
            commodityDescription:'',
            specialInstructions:'',
        }
    },
    validations: {
        email: { required }
    },
    methods: {
        getQuoteClick() {
            const mailToLink = `mailto:info@clarityfreight.com?subject=Clarity%20Freight%20-%20Get%20A%20Quote
&body=Hey there, I'd like to request a quote based on the following: %0A%0A
Company Name: ${encodeURI(this.companyName)}%0A%0A
Phone: ${encodeURI(this.phone)}%0A%0A
My Email: ${encodeURI(this.email)}%0A%0A
Origin Zip/City: ${encodeURI(this.origin)}%0A%0A
Destination Zip/City: ${encodeURI(this.destination)}%0A%0A
Number of Pallets: ${encodeURI(this.numPallets)}%0A%0A
Dimensions: ${encodeURI(this.dimensions)}%0A%0A
Total Weight: ${encodeURI(this.totalWeight)}%0A%0A
Description of Commodity: ${encodeURI(this.commodityDescription)}%0A%0A
Special Instructions: ${encodeURI(this.specialInstructions)}%0A%0A
Thank you!%0A
${encodeURI(this.contactName)}`;
            
            window.location.href = mailToLink;

        }
    }
}
</script>
<style scoped>
.error-message{
    color:red;
    position:relative;
    top:1px;
    left:4px;
}
.form-card{
    padding:30px;
    margin-bottom:10%;
}
.quote-image{
    margin-top:12%;
}
.quote-header{
    margin-top:5%;
    margin-bottom:5%;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .1ch;
    font-size:28px;
    color: var(--clarity-green);
}
.quote-text{
    text-transform: uppercase;
    letter-spacing: .1ch;
    color:white;
    margin-top:10%;
    font-size:26px;
}
</style>